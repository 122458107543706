var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: { title: _vm.setTitle, show: _vm.open, isForm: _vm.isForm },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c("info-row", { attrs: { label: "名称" } }, [
            _vm._v(_vm._s(_vm.curRow.productionName)),
          ]),
          _c("info-row", { attrs: { label: "序号" } }, [
            _vm._v(_vm._s(_vm.curRow.sequenceNo)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "作品名称", prop: "productionName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入作品名称" },
                    model: {
                      value: _vm.formData.productionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "productionName", $$v)
                      },
                      expression: "formData.productionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "暂用名", prop: "productionName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入暂用名" },
                    model: {
                      value: _vm.formData.temporaryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "temporaryName", $$v)
                      },
                      expression: "formData.temporaryName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "外语名", prop: "foreignName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入外语名" },
                    model: {
                      value: _vm.formData.foreignName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "foreignName", $$v)
                      },
                      expression: "formData.foreignName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "说明", prop: "description" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入说明内容" },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "description" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.formData.extra,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "extra", $$v)
                      },
                      expression: "formData.extra",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择统筹", prop: "adminUsers" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择统筹人员" },
                      on: { change: _vm.changeAdminUser },
                      model: {
                        value: _vm.selcetAdminUser,
                        callback: function ($$v) {
                          _vm.selcetAdminUser = $$v
                        },
                        expression: "selcetAdminUser",
                      },
                    },
                    _vm._l(_vm.userList, function (i) {
                      return _c("a-select-option", { key: i.id }, [
                        _vm._v(
                          "\n            " + _vm._s(i.realname) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择助手", prop: "assistantUsers" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择助手用户" },
                      on: { change: _vm.changeAssistantUser },
                      model: {
                        value: _vm.selcetAssUser,
                        callback: function ($$v) {
                          _vm.selcetAssUser = $$v
                        },
                        expression: "selcetAssUser",
                      },
                    },
                    _vm._l(_vm.userList, function (i) {
                      return _c("a-select-option", { key: i.id }, [
                        _vm._v(
                          "\n            " + _vm._s(i.realname) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择平台", prop: "platformId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择平台" },
                      model: {
                        value: _vm.formData.platformId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "platformId", $$v)
                        },
                        expression: "formData.platformId",
                      },
                    },
                    _vm._l(_vm.platformList, function (i) {
                      return _c("a-select-option", { key: i.id }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(i.platformName) +
                            "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }