<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <!-- 详情 -->
    <div class="">
      <info-row label="名称">{{ curRow.productionName }}</info-row>
      <info-row label="序号">{{ curRow.sequenceNo }}</info-row>
    </div>
    <!-- 表单 -->
    <div slot="form">
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="作品名称" prop="productionName">
          <a-input v-model="formData.productionName" placeholder="请输入作品名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="暂用名" prop="productionName">
          <a-input v-model="formData.temporaryName" placeholder="请输入暂用名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="外语名" prop="foreignName">
          <a-input v-model="formData.foreignName" placeholder="请输入外语名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="说明" prop="description">
          <a-textarea v-model="formData.description" placeholder="请输入说明内容" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="description">
          <a-textarea v-model="formData.extra" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item label="选择统筹" prop="adminUsers">
          <a-select placeholder="请选择统筹人员" v-model="selcetAdminUser" @change="changeAdminUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择助手" prop="assistantUsers">
          <a-select placeholder="请选择助手用户" v-model="selcetAssUser" @change="changeAssistantUser">
            <a-select-option v-for="i in userList" :key="i.id">
              {{ i.realname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择平台" prop="platformId">
          <a-select placeholder="请选择平台" v-model="formData.platformId">
            <a-select-option v-for="i in platformList" :key="i.id">
              {{ i.platformName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
export default {
  mixins: [DrawerMixins],

  data() {
    return {
      selcetAdminUser: [],
      selcetAssUser: [],
      defaultStageItem: {
        id: 0,
        positionId: '',
        positionName: '',
        predefinedTime: 0,
        productionId: 0,
        rate: true,
        reversible: true,
        ruleId: 0,
        sequenceNo: 0,
        stageId: 0,
        stageName: '',
        userId: '',
        userName: '',
      },
      rules: {
        productionName: [{ required: true, message: '请输入作品名称', trigger: 'blur' }],
      },
      url: {
        edit: '/production/edit',
        add: '/production/add',
      },
    }
  },
  methods: {
    initForm() {
      this.formData = {
        adminUser: {},
        assistantUser: {},
        coefficientRuleId: 0,
        description: '',
        extra: '',
        foreignName: '',
        moneyUnit: null,
        parameters: null,
        platformId: null,
        productionName: '',
        sequenceNo: 0,
        temporaryName: '',
        stageList: [Object.assign({}, this.defaultStageItem)],
        departmentIds: [],
      }
    },

    show() {
      console.log('显示...')
      this.getUserList()
      this.getDepartList()
      this.getPosition()
      this.getPlatformList()

      this.isEdit = this.isEditForm
      if (this.isEdit) {
        console.log(this.curRow)
        const { assistantUsers, adminUser } = this.formData
        this.selcetAdminUser = adminUser.userId
        this.selcetAssUser = assistantUsers.userId
      }
    },

    changeAdminUser(val) {
      console.log(val)
      this.formData.adminUser = {
        userId: val,
      }
    },
    changeAssistantUser(val) {
      this.formData.assistantUser = {
        userId: val,
      }
    },

    setFormData() {
      console.log(this.formData)
    },
  },
  created() {
    this.initForm()
  },
}
</script>

<style lang='stylus'></style>